<template>
  <el-dialog
    class="table-view-modal"
    :visible.sync="dialogVisible"
    width="800px"
    lock-scroll
    modal
    close-on-click-modal
    destroy-on-close>
    <el-container>
      <el-header
        v-if="type !== 'push'">
        <h3>{{ titleDisplay }}</h3>
      </el-header>

      <el-main v-if="type === 'feedback'">
        <template>
          <div class="info">
            类别：{{ contain ? contain.select_item : '' }}<br><br>
            用户名：{{ contain ? contain.username : '' }}<br><br>
            昵称：{{ contain ? contain.nickname : '' }}<br><br>
            内容：{{ contain ? contain.content : '' }}
          </div>
        </template>
      </el-main>

      <el-main v-if="type === 'contact'">
        <template>
          <div class="info">
            类别：{{ contain ? contain.select_item : '' }}<br><br>
            用户名：{{ contain ? contain.username : '' }}<br><br>
            昵称：{{ contain ? contain.nickname : '' }}<br><br>
            内容：{{ contain ? contain.content : '' }}<br><br>
            客服回覆：{{ contain && contain.remark ? contain.remark : '尚未回覆' }}
          </div>
        </template>
      </el-main>

      <el-main v-if="type === 'benefit'">
        <template>
          <div class="info">
            <el-image class="image" v-if="contain && contain.photo" :src="contain.photo" fit="cover" />
            <br><br>
            活动时间：<br>
            <span>{{contain ? contain.start_at : ''}} 至 {{contain ? contain.end_at : ''}}</span>
            <br><br>
            活动内容：
            <div
              v-if="contain"
              v-html="contain.content"
              class="">
            </div>
          </div>
        </template>
      </el-main>

      <el-main v-if="type === 'push'">
        <template>
          <div class="info">
            发送标题：{{ contain ? contain.title : '' }}<br>
            发送对象：{{ contain ? handleTarget(contain) : '' }}<br>
            <div v-if="contain && contain.target_id && memberInfo">
              对象资讯：{{ memberInfo.nickname }} ({{ memberInfo.username }})
            </div>
            消息类型：{{ contain ? handleType(contain) : '' }}<br>
            发送时间：{{ contain ? contain.updated_at : '' }}<br>
            推送內容：
            <div
              v-if="contain"
              style="white-space: pre-wrap;"
              class="article">
              {{ contain.content }}
            </div>
          </div>
        </template>
      </el-main>

      <el-main v-if="type === 'news'">
        <template>
          <div class="info">
            <span>{{contain ? contain.start_at : ''}}</span>
            <span>{{contain && contain.source ? `来源：${contain.source}` : ''}}</span>
          </div>
          <div
            v-if="contain"
            v-html="contain.content"
            class="article">
          </div>
        </template>
      </el-main>

      <el-footer>
        <el-button type="primary" @click="toggleVisible(false)">确定</el-button>
      </el-footer>
    </el-container>
  </el-dialog>
</template>

<script>
import options from '@/utils/options'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    contain: {
      type: Object,
      default: null
    },
    memberInfo: {
      default: null
    },
    type: {
      type: String,
      require: true
    }
  },
  computed: {
    titleDisplay() {
      if (
        this.type === 'news' ||
        this.type === 'benefit')
        return this.contain ? this.contain.title : ''
      if (
        this.type === 'feedback' ||
        this.type === 'contact')
        return '查看详情'
    },
    dialogVisible: {
      get () {
        return this.visible
      },
      set (boo) {
        this.toggleVisible(boo)
      }
    }
  },
  methods: {
    handleType(row) {
      let data = ``
      options.pushTypeList.forEach(key => {
        if (key.value === row.type)
          data = key.label
      })
      return data
    },
    handleTarget(row) {
      let data = ``
      options.pushTargetList.forEach(key => {
        if (key.value === row.target)
          data = key.label
      })
      return data
    },
    toggleVisible (boo) {
      this.$emit('toggleDialog', boo)
    }
  }
}
</script>
<style lang="scss" scoped>
  .image {
    width: 100%;
    height: 300px;
  }
  .table-view-modal{
    max-width: 100%;
    .el-header {
      text-align: left;
      max-height: auto;
      h3 {font-size: 1.75em;}
    }
    .el-main {
      padding-top: 0;
      .info {
        text-align: left;
        margin: 5px 0 20px;
        & span {
          padding-right: 20px;
        }
      }
      .article {
        text-align: left;
        margin: 20px 0;
      }
      p img{
        max-width: 100%!important;
      }
    }
  }
</style>
