import request from '@/utils/axios'
import { getFormData } from '@/utils/axiosTools'

export function benefitGetList (params) {
  return request({
    url: '/api/console/lottery/benefit',
    method: 'get',
    params: params
  })
}

export function benefitDetail (id) {
  return request({
    url: `/api/console/lottery/benefit/${id}`,
    method: 'get'
  })
}

export function benefitChangeBanner (id, status) {
  return request({
    url: `/api/console/lottery/benefit/${id}`,
    method: 'get',
    params: {
      on_banner: status
    }
  })
}

export function benefitChangeStatus (id, status) {
  return request({
    url: `/api/console/lottery/benefit/${id}`,
    method: 'get',
    params: {
      is_visible: status
    }
  })
}

export function benefitDelete (id) {
  return request({
    url: `/api/console/lottery/benefit/${id}`,
    method: 'delete'
  })
}

export function benefitDeletePhoto (id) {
  return request({
    url: `/api/console/lottery/benefit/${id}/photo`,
    method: 'delete'
  })
}

export function benefitAdd (data) {
  return request({
    url: '/api/console/lottery/benefit',
    method: 'post',
    data: getFormData(data)
  })
}

export function benefitEdit (id, data) {
  return request({
    url: `/api/console/lottery/benefit/${id}`,
    method: 'post',
    data: getFormData(data)
  })
}

export function postEditorPics (data) {
  return request({
    url: '/api/console/ckeditor/upload',
    method: 'post',
    data: getFormData(data)
  })
}
