<template>
  <div>
    <div v-if="isEditable">
      <router-view />
    </div>
    <div v-else>
      <Head
        :keyword="query.keyword"
        :status="query.is_visible"
        :on_banner="query.on_banner"
        @handleQuery="handleQuery"
        @handleRefresh="handleRefresh" />
      <Table
        :tableData="pageData.data"
        :editable="editable"
        :removal="removal"
        @handlePreview="handlePreview"
        @handleDelete="handleDelete"
        @handleRefresh="handleRefresh" />
      <Pagination
        :query="query"
        :pageData="pageData"
        @handleQuery="handleQuery"
        @handleRefresh="handleRefresh" />
      <el-dialog
        title="刪除提示"
        :visible.sync="centerDialogVisible"
        width="30%"
        center>
        <span>是否确认删除？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="checkDelete">确 定</el-button>
        </span>
      </el-dialog>
      <FullScreenDialog
        type="benefit"
        :visible="showPreview"
        :contain="previewContent"
        @toggleDialog="toggleDialog" />
    </div>
  </div>
</template>

<script>
import { forIn, has } from 'lodash'
import resStatus from '@/constants/resStatus'
import { benefitGetList, benefitDelete } from '@/api/admin/benefit'
import { adminLogout } from '@/utils/admin'

import Head from './Head'
import Table from './Table'
import Pagination from '@/components/admin/pagination'
import FullScreenDialog from '@/components/admin/full-screen-dialog'

export default {
  components: {
    Head,
    Table,
    Pagination,
    FullScreenDialog
  },
  data () {
    return {
      centerDialogVisible: false,
      query: {
        page: 1,
        pageSize: 10,
        keyword: '',
        is_visible: '',
        on_banner: ''
      },
      editable: true,
      removal: true,
      pageData: {
        data: []
      },
      isEditable: false,
      deleteId: '',
      showPreview: false,
      previewContent: null
    }
  },
  methods: {
    init () {
      const pageName = this.$route.name
      switch (pageName) {
        case 'AdminMarketBenefitAdd':
        case 'AdminMarketBenefitEdit':
          this.isEditable = true
          break
        default:
          this.isEditable = false
          benefitGetList(this.query).then(res => {
            switch (res.status) {
              case resStatus.OK:
                this.pageData = res
                break
              default:
                adminLogout()
                this.$message.error(res.message)
            }
          }).catch(err => {
            adminLogout()
            this.$message.error(err)
          })
          break
      }
    },
    handleQuery (obj) {
      forIn(obj, (val, key) => {
        if (has(this.query, key)) {
          this.query[key] = val
        }
      })
    },
    handleEditable (boo) {
      this.editable = boo
    },
    handleDelete (id) {
      this.centerDialogVisible = true
      this.deleteId = id
    },
    checkDelete () {
      this.centerDialogVisible = false
      benefitDelete(this.deleteId).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.handleRefresh()
            break
          default:
            adminLogout()
            this.$message.error(res.message)
        }
      }).catch(err => {
        adminLogout()
        this.$message.error(err)
      })
    },
    handleRefresh () {
      this.init()
    },
    handlePreview (obj) {
      this.previewContent = obj
      this.showPreview = true
    },
    toggleDialog (boo) {
      this.showPreview = boo
    }
  },
  created () {
    this.init()
  },
  watch: {
    $route (to, from) {
      this.init()
    }
  }
}
</script>
